import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"

import { SEO } from "../components/Seo"
import { Header } from "../components/Header"
import { ButtonLabel } from "../elements/ButtonLabel"
import { IconArrow } from "../icons/IconArrow"

import { BUTTON__BACK, COOKIES, SITENAME } from "../providers/texts"
import { HOTJAR, GOOGLE_ANALYTICS, BLOCK_COOKIES } from "../providers/urls"

import styles from "./cookies.module.scss"

const Cookies: React.FC = () => {
  const heroImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "_general/cookies.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title={`${SITENAME} • ${COOKIES}`} />
      <div className={styles.container}>
        <Header resetState={() => navigate("/")} />

        <div className={styles.content}>
          <Img
            fluid={heroImage.file.childImageSharp.fluid}
            className={styles.image}
            alt={COOKIES}
          />
          <div className={styles.text}>
            <div className={styles.description}>
              <div className={styles.description__paragraphFirst}>
                Milí návštěvníci,
              </div>
              <div className={styles.description__paragraph}>
                tyto webové stránky používají cookies, malé textové soubory,
                které se ukládají do Vašeho prohlížeče. Obsahují informace o
                {"\xa0"}Vaší návštěvě, například o{"\xa0"}tom, jaký je Váš
                preferovaný jazyk, případně o{"\xa0"}dalších uživatelských
                předvolbách.
              </div>
              <div className={styles.description__paragraph}>
                Tyto stránky využívají cookies pouze pro základní analýzu
                návštěvnosti (
                <a
                  className={styles.description__link}
                  href={GOOGLE_ANALYTICS}
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Analytics
                </a>
                ,{" "}
                <a
                  className={styles.description__link}
                  href={HOTJAR}
                  target="_blank"
                  rel="noreferrer"
                >
                  Hotjar
                </a>
                ). Takto získané – zcela anonymizované – údaje následně slouží
                jako podklady k{"\xa0"}
                vylepšování stránek a{"\xa0"}uživatelské zkušenosti. Nejsou
                využívány pro účely reklamy, získávání jakýchkoli citlivých
                údajů, ani k{"\xa0"}identifikaci konkrétních osob.
              </div>
              <div className={styles.description__title}>
                Jaké cookies používají Přebalovny.cz?
              </div>
              <div className={styles.description__paragraph}>
                <ul>
                  <li>
                    <span className={styles.description__emphasis}>
                      gatsby-gdpr-google-analytics
                    </span>{" "}
                    slouží k{"\xa0"}zapamatování si, zda byl udělen souhlas s
                    {"\xa0"}ukládáním cookies v{"\xa0"}informačním banneru.
                  </li>
                  <li>
                    <span className={styles.description__emphasis}>_ga*</span>{" "}
                    slouží k{"\xa0"}mapování uživatelské aktivity na webu
                    nástrojem Google Analytics.
                  </li>
                  <li>
                    <span className={styles.description__emphasis}>_hj*</span>{" "}
                    slouží k{"\xa0"}mapování uživatelské aktivity na webu
                    nástrojem HotJar.
                  </li>
                  <li>
                    <span className={styles.description__emphasis}>
                      pragueBoundsCheck
                    </span>{" "}
                    slouží k{"\xa0"}zapamatování si, zda se uživatel nachází na
                    území Prahy (pokud ne, je upozorněn, že mapa přebaloven je
                    aktuálně lokalizovaná jen na hlavní město).
                  </li>
                </ul>
              </div>

              <div className={styles.description__title}>Souhlas s cookies</div>

              <div className={styles.description__paragraph}>
                Máte-li použití cookies povoleno v{"\xa0"}prohlížeči,
                předpokládáme, že souhlasíte s{"\xa0"}využíváním standardních
                cookies také v{"\xa0"}rámci webových stránek Přebalovny.cz
                (včetně podstránek). V případě, že si nepřejete poskytovat
                jakákoli data, lze poskytování cookies zakázat plošně v{"\xa0"}
                prohlížeči – návod najdete například na{" "}
                <a
                  className={styles.description__link}
                  href={BLOCK_COOKIES}
                  target="_blank"
                  rel="noreferrer"
                >
                  Jak zablokovat cookies
                </a>
                .
              </div>
            </div>

            <div className={styles.button}>
              <Link to="/">
                <ButtonLabel ariaLabel={BUTTON__BACK}>
                  <>
                    <IconArrow />
                    <div className={styles.button__label}>{BUTTON__BACK}</div>
                  </>
                </ButtonLabel>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cookies
